/* eslint-disable camelcase */
import React from 'react'
import {Header} from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import {useStaticQuery, graphql} from 'gatsby'

export default ({Description}) => {
  const {strapiMessage} = useStaticQuery(
    graphql`
    query {
      strapiMessage(Key: {eq: "AboutProduct"}) {
        Value
      }
    }
    `,
  )
  return (
    <div>
      <Header as="h3">{strapiMessage.Value}</Header>
      <ReactMarkdown source={Description} />
      {/* <Divider /> */}
    </div>
  )
}