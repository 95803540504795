import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/SEO'
import ProductSummary from '../components/ProductSummary'
import ProductAttributes from '../components/ProductAttributes'
import Layout from "../components/Layout"

export const query = graphql`
  query ProductQuery($id: String) {
    strapiProduct(strapiId: { eq: $id }) {
      strapiId
      Name
      Price
      Description
      MetaDescription
      Image {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
          compressed:sizes(sizes: "200") {
            src
          }
        }
        publicURL
      } 
      Gallery {
        url
      }
      Category {
        Name
        id
      }            
    }
  }
`

const Product = ({ data, location }) => {
  const product = data.strapiProduct
  const metadata = {
    description: product.MetaDescription,
    title: product.Name,
    image: product.Image.childImageSharp.compressed.src
  }
  location.category = product.Category
  location.product = product
  return (
    <Layout location = {location} >
      <SEO title={product.Name} metadata={metadata}/>
      <ProductSummary {...product} />
      <ProductAttributes {...product} />
    </Layout>
  )
}

export default Product
