import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import {Item, Button, Icon} from 'semantic-ui-react'
import {useStaticQuery, graphql} from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';

export default ({Name, Price, Gallery, Image}) => {
  const [toggler, setToggler] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const {strapiMessage, strapiFooter} = useStaticQuery(
    graphql`
    query {
      strapiMessage(Key: {eq: "ImInterested"}) {
        Value
      }
      strapiFooter(id: {ne: ""}) {
        WhatsappNumber
      }
    }
    `,
  )
  let images = Gallery.map(img => img.url)
  if(images.length === 0){
    images.push(Image.childImageSharp.sizes.src)
  }

  const elements = images.map((src, i) => (
    <img src={src} alt={`${i}-${Name}`} key={i}/>
  ))

  const updateCurrentSlide = (index) => {
    if (photoIndex !== index) {
      setPhotoIndex(index);
    }
};
  const whatsappMessage = encodeURI(strapiMessage.Value.replace('[product]', Name));
  const whatsappNumber = strapiFooter.WhatsappNumber;
  const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`
  return (
    <Item.Group>
      <Item style={{alignItems: 'center'}}>
        <Item.Image size='medium' style={{width: '250px'}}>
          <div style={{width: '250px'}}>
            <Carousel onClickItem = {() => setToggler(!toggler)} renderThumbs = {() => null} onChange={updateCurrentSlide} showStatus={false}>
              {elements}
            </Carousel>
          </div>
          {toggler && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setToggler(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
          )}
        </Item.Image>

        <Item.Content>
          <Item.Header>{Name}</Item.Header>
          <Item.Description>
            <CurrencyFormat value={Price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </Item.Description>
          <Item.Extra>
            <Button color='green' onClick={() => window.open(whatsappUrl, "_blank")}>
              <Icon name='whatsapp' />
              Whatsapp
            </Button>
            {/* <AddToCart productId={id} /> */}
          </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
    
  )
}